import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { ExportType } from "../../../../../api/entities";

export interface ExportTypeSelectorProps {
  value: ExportType;
  onChange: (event: ExportType) => void;
}

const dataSourceOptions: { label: string; value: ExportType }[] = [
  { label: "Purchase", value: "purchase" },
  { label: "Scan", value: "scan" },
  { label: "Purchase & Scan", value: "mix" },
];

// Select from where the initial data should come from.
// Valid options are:
// Scan, only use users scan data to base recommendations on.
// Purchase, use purchase history to select users.
// Mix, use both scan data and purshace history.
export const ExportTypeSelector: React.FC<ExportTypeSelectorProps> = ({
  value,
  onChange,
}) => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as ExportType);
  };

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="scan-type-input">Data source</InputLabel>
      <Select
        fullWidth
        id="scan-type-input"
        value={value}
        onChange={handleChange}
      >
        {dataSourceOptions.map((o) => (
          <MenuItem key={o.value} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        What data source should be used to select subjects from.
      </FormHelperText>
    </FormControl>
  );
};
