import { useLocation } from "react-router-dom";

import tenants from "../tenant-config.json";

export interface Tenant {
  id: string;
  name: string;
  image: string;
}

export const useQuery = () => new URLSearchParams(useLocation().search);

export const useTenant = (): Tenant | null => {
  const q = useQuery();

  const tenant = tenants.find((element) => {
    return element.id === q.get("tenant");
  });

  if (tenant == null) {
    return null;
  }

  return tenant;
};
