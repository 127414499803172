import React from "react";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  ExportType,
  RecommendationFilters,
  CategoryFilter,
  CategoryFilterSelector,
} from "../../../../../api/entities";
import { FiltFilter } from "./FitFilter";
import { ImageFilter } from "./ImageFilter";
import { ShoeAvailability } from "./ShoeAvailability";
import { MatchingOperatorSelector } from "./MatchingOperatorSelector";
import { ShoeBrandFilterSettings } from "./ShoeBrandFilterSettings";

export interface RecommendationFilterSettingsProps {
  exportType: ExportType;
  value: RecommendationFilters;
  onChange: (event: RecommendationFilters) => void;
}

export const RecommendationFilterSettings: React.FC<RecommendationFilterSettingsProps> =
  ({ exportType, value, onChange }) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={"h5"}>Recommendation filters</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FiltFilter
            value={value.fit_filter}
            onChange={(change) => {
              onChange({ ...value, fit_filter: change });
            }}
          ></FiltFilter>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ImageFilter
            value={value.available_shoe_images}
            onChange={(change) => {
              onChange({ ...value, available_shoe_images: change });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ShoeAvailability
            value={value.available_shoes}
            onChange={(change) => {
              onChange({ ...value, available_shoes: change });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ShoeBrandFilterSettings
            value={value.shoe_brand_filter}
            onChange={(change) => {
              onChange({ ...value, shoe_brand_filter: change });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CategoryFilterSettings
            exportType={exportType}
            value={value.category_filter}
            onChange={(change) => {
              onChange({ ...value, category_filter: change });
            }}
          />
        </Grid>
      </Grid>
    );
  };

interface CategoryFilterSettingsProps {
  exportType: ExportType;
  value: CategoryFilter;
  onChange: (event: CategoryFilter) => void;
}

const CategoryFilterSettings: React.FC<CategoryFilterSettingsProps> = ({
  exportType,
  value,
  onChange,
}) => {
  return (
    <Grid item xs={12} spacing={2}>
      <Grid item xs={12}>
        <Typography>
          <b>Category filter</b>
        </Typography>
        <Typography variant={"caption"}>
          Filter shoes to only recommend ones that match the provided
          categories.
        </Typography>
      </Grid>
      <Grid item xs={12} spacing={2}>
        <FormControl fullWidth>
          <Select
            fullWidth
            value={value.selector}
            onChange={(event) => {
              onChange({
                ...value,
                selector: event.target.value as CategoryFilterSelector,
              });
            }}
          >
            {exportType !== "scan" && (
              <MenuItem value={"reference"}>
                Previous purchase reference categories
              </MenuItem>
            )}
            <MenuItem value={"category"}>Category</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item style={{ paddingTop: 10 }}>
        <Grid container spacing={2}>
          {value.selector === "category" && (
            <Grid item xs={8} sm={10}>
              <TextField
                fullWidth
                label="Categories"
                helperText={
                  "Comma separated string of categories to be matched against the recommended shoes."
                }
                value={value.categories}
                onChange={(event) => {
                  onChange({ ...value, categories: [event.target.value] });
                }}
              />
            </Grid>
          )}
          <Grid item xs={4} sm={2}>
            <MatchingOperatorSelector
              value={value.matching_operator}
              onChange={(change) => {
                onChange({ ...value, matching_operator: change });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
