import React from "react";
import { TextField } from "@material-ui/core";

export interface FiltFilterProps {
  value: number;
  onChange: (event: number) => void;
}

export const ImageFilter: React.FC<FiltFilterProps> = ({ value, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let v = parseInt(event.target.value, 10);
    if (isNaN(v)) {
      v = 0;
    }
    onChange(v);
  };

  return (
    <TextField
      fullWidth
      label="Number of Images"
      helperText="Minimum amount of recommended shoes that have a product image."
      value={value}
      onChange={handleChange}
    />
  );
};
