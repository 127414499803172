import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

import { MatchingOperator } from "../../../../../api/entities";

const matchingOperatorOptions: { label: string; value: MatchingOperator }[] = [
  { label: "All", value: "ALL" },
  { label: "Any", value: "ANY" },
  { label: "Exact", value: "EXACT" },
];

export interface MatchingOperatorSelectorProps {
  value: MatchingOperator;
  onChange: (event: MatchingOperator) => void;
}

export const MatchingOperatorSelector: React.FC<MatchingOperatorSelectorProps> =
  ({ value, onChange }) => {
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      onChange(event.target.value as MatchingOperator);
    };

    return (
      <FormControl fullWidth>
        <InputLabel htmlFor="categories-matcher-selector">Matcher</InputLabel>
        <Select
          fullWidth
          id="categories-matcher-selector"
          value={value}
          onChange={handleChange}
        >
          {matchingOperatorOptions.map((o) => (
            <MenuItem key={o.value} value={o.value}>
              {o.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
