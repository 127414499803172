export const __prod__ = process.env.NODE_ENV === "production";
export const targetEnvironment = process.env.REACT_APP_STATE;
export const apiBaseUrl = (() => {
  if (targetEnvironment === "production") {
    return "https://promo.volumental.com";
  } else if (targetEnvironment === "staging") {
    return "https://promo.volumental.dev";
  } else {
    return "http://localhost:8000";
  }
})();
