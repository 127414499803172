import { Box, Container, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { DoneAllOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: 40,
    marginBottom: 40,
    padding: theme.spacing(3),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  icon: {
    fontSize: 140,
    color: theme.palette.success.main,
  },
}));

export const SignedOutPage = () => {
  const classes = useStyles();

  return (
    <Container>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box textAlign={"center"}>
              <DoneAllOutlined className={classes.icon} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h6"} align={"center"}>
              You have been signed out successfully!
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
