import React from "react";
import { TextField } from "@material-ui/core";

export interface NumberOfRecommendationsInputProps {
  value: number;
  onChange: (event: number) => void;
}

export const NumberOfRecommendationsInput: React.FC<NumberOfRecommendationsInputProps> =
  ({ value, onChange }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let v = parseInt(event.target.value, 10);
      if (isNaN(v)) {
        v = 0;
      }
      onChange(v);
    };

    return (
      <TextField
        fullWidth
        label="Export recommendations"
        helperText="Number of recommendations to be created for each subject in the export."
        value={value}
        onChange={handleChange}
      />
    );
  };
