import React from "react";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { Route, Switch, Redirect, RouteProps } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { TopBar } from "../topbar";
import theme from "../../theme";
import { AdminPage } from "../admin";
import Loading from "../loading";
import { SignedOutPage } from "../signedOut/SignedOut";
import { QueryClientProvider } from "react-query";
import { queryClient } from "../../lib/queryClient";
import { SnackbarProvider } from "notistack";
export const App = () => {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3} preventDuplicate={true}>
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <Switch>
            <ProtectedRoute path={"/admin"}>
              <TopBar />
              <AdminPage />
            </ProtectedRoute>
            <Route path="/signed-out">
              <TopBar />
              <SignedOutPage />
            </Route>
            <Redirect to="/admin" />
          </Switch>
        </QueryClientProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

const ProtectedRoute: React.FC<RouteProps> = ({ children, ...args }) => {
  let component = () => <React.Fragment>{children}</React.Fragment>;
  return (
    <Route
      component={withAuthenticationRequired(component, {
        onRedirecting: () => <Loading />,
      })}
      {...args}
    />
  );
};
