import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormGroup,
  Grid,
  Typography,
} from "@material-ui/core";
import { Store } from "../../../../../api/entities";

export interface StoreSettingsProps {
  value: Store;
  onChange: (value: Store) => void;
}

// Select from where the initial data should come from.
// Valid options are:
// Scan, only use users scan data to base recommendations on.
// Purchase, use purchase history to select users.
// Mix, use both scan data and purshace history.
export const StoreSettingsFilter: React.FC<StoreSettingsProps> = ({
  value,
  onChange,
}) => {
  const handleTagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      store_info: event.target.checked,
    });
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      filter_corporate_store: !event.target.checked,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={"h5"}>Store information</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={value.store_info}
                  onChange={handleTagChange}
                />
              }
              label="Point of sale"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={!value.filter_corporate_store}
                  onChange={handleImageChange}
                />
              }
              label="Corporate"
            />
            <FormHelperText>
              Select which store information that should be included in the
              export.
            </FormHelperText>
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};
