import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Error as ErrorIcon } from "@material-ui/icons";
import { useTenant } from "../../hooks/useQuery";
import SaveIcon from "@material-ui/icons/Save";
import { ExportSettingsEditor } from "./compents/settings/ExportSettings";
import Loading from "../loading";
import { TenantConfiguration } from "../../api/entities";
import { useGetConfiguration, useUpdateConfiguration } from "../../api/base";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: 40,
    marginBottom: 40,
    padding: theme.spacing(3),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  actionButtom: {
    textTransform: "uppercase",
    margin: theme.spacing(1),
    width: 152,
  },
  alignRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  title: {
    display: "flex",
  },
  titleImage: {
    marginRight: theme.spacing(1),
  },
  icon: {
    fontSize: 140,
    color: theme.palette.error.main,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export const AdminPage = () => {
  const classes = useStyles();
  const tenant = useTenant();
  const { mutate, isLoading: isUpdating } = useUpdateConfiguration(
    tenant?.id || ""
  );
  const { data, isLoading, isError } = useGetConfiguration(tenant?.id || "");
  const [changes, setChanges] = useState<TenantConfiguration>();
  const [isDiff, setDiff] = useState<boolean>(false);
  useEffect(() => {
    setChanges(data);
  }, [data]);

  useEffect(() => {
    setDiff(JSON.stringify(data) === JSON.stringify(changes));
  }, [changes, data]);

  if (tenant == null) {
    return (
      <Container>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={"h5"} align={"center"}>
                Select a tenant!
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }

  if (isLoading || !changes) {
    return (
      <Container>
        <Paper className={classes.paper}>
          <Loading />
        </Paper>
      </Container>
    );
  }

  if (isError || !changes) {
    return (
      <Container>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box textAlign={"center"}>
                <ErrorIcon className={classes.icon} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={"h6"} align={"center"}>
                Could not load configuration for {tenant.name}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }

  return (
    <Container>
      <Paper className={classes.paper}>
        <Box pt={2} pr={2} pl={2} pb={2}>
          <Box className={classes.title}>
            <Avatar
              variant="rounded"
              src={tenant.image}
              className={classes.titleImage}
            />
            <Typography variant="h4">
              <b>{tenant.name}</b>
            </Typography>
          </Box>
          <Box>
            <Typography variant={"subtitle2"}>
              <b>Transaction Supplier: </b>
              {data?.transaction_supplier}
            </Typography>
          </Box>
          <Box>
            <ExportSettingsEditor
              tc={changes}
              onChange={(e) => setChanges(e)}
            />
          </Box>
        </Box>

        <Box className={classes.alignRight}>
          {!(isUpdating || isDiff) && (
            <Button
              color="secondary"
              variant="contained"
              className={classes.actionButtom}
              onClick={() => {
                setChanges(data);
              }}
            >
              Reset
            </Button>
          )}
          <Button
            color="primary"
            disabled={isUpdating || isDiff}
            variant="contained"
            className={classes.actionButtom}
            onClick={() => {
              mutate(changes);
            }}
            endIcon={<SaveIcon />}
          >
            Save
            {isUpdating && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};
