import React, { useMemo } from "react";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import {
  PreviousPurchaseReference,
  ReferenceShoe,
  ReferenceShoeSelector,
} from "../../../../../api/entities";
import { MatchingOperatorSelector } from "./MatchingOperatorSelector";

const scanOptions: { label: string; value: ReferenceShoeSelector }[] = [
  { label: "Latest purchase", value: "latest" },
  { label: "Category", value: "category" },
];

export interface FiltFilterProps {
  data: PreviousPurchaseReference;
  referenceSelector: ReferenceShoe;
  onDataChange: (value: PreviousPurchaseReference) => void;
  onSelectorChange: (value: ReferenceShoe) => void;
}

export const PreviousPurchaseFilter: React.FC<FiltFilterProps> = ({
  data,
  referenceSelector,
  onDataChange,
  onSelectorChange,
}) => {
  const handleTagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onDataChange({
      ...data,
      shoe_features: event.target.checked,
    });
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onDataChange({
      ...data,
      shoe_images: event.target.checked,
    });
  };

  let categorySelector = useMemo(() => {
    if (referenceSelector.selector === "category") {
      return (
        <Grid container item xs={12} spacing={2} style={{ paddingTop: 0 }}>
          <Grid item xs={12} sm={10}>
            <FormControl fullWidth>
              <InputLabel htmlFor="my-helper-text">Categories</InputLabel>
              <Input
                fullWidth
                id="my-input"
                value={(referenceSelector.categories || [""])[0]}
                aria-describedby="my-helper-text"
                onChange={(event) => {
                  onSelectorChange({
                    ...referenceSelector,
                    categories: [event.target.value],
                  });
                }}
              />
              <FormHelperText>
                Comma separated string of categories to be matched against a
                previous purchase.
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <MatchingOperatorSelector
              value={referenceSelector.matching_operator}
              onChange={(value) => {
                onSelectorChange({
                  ...referenceSelector,
                  matching_operator: value,
                });
              }}
            />
          </Grid>
        </Grid>
      );
    }
    return null;
  }, [referenceSelector, onSelectorChange]);

  return (
    <Grid container item spacing={2}>
      <Grid item xs={12}>
        <Typography variant={"h5"}>Previous purchase</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel htmlFor="reference-selector">Reference shoe</InputLabel>
          <Select
            fullWidth
            id="reference-selector"
            value={referenceSelector.selector}
            onChange={(event) => {
              onSelectorChange({
                ...referenceSelector,
                selector: event.target.value as ReferenceShoeSelector,
              });
            }}
          >
            {scanOptions.map((o) => (
              <MenuItem key={o.value} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            Decides how the reference shoe that can be used filter
            recommendations on will be selected.
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl component="fieldset">
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.shoe_features}
                  onChange={handleTagChange}
                />
              }
              label="Shoe features"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.shoe_images}
                  onChange={handleImageChange}
                />
              }
              label="Image"
            />
          </FormGroup>
          <FormHelperText>
            Select which information about the reference shoe that should be
            included.
          </FormHelperText>
        </FormControl>
      </Grid>
      {categorySelector}
    </Grid>
  );
};
