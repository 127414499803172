import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { ExportTypeSelector } from "./components/ExportTypeSelector";
import { StoreSettingsFilter } from "./components/StoreSettingsFilter";
import { PreviousPurchaseFilter as PPFilter } from "./components/PreviousPurchaseFilter";
import { NumberOfRecommendationsInput } from "./components/NumberOfRecommendations";
import { RecommendationFilterSettings } from "./components/RecommendationFilterSettings";
import { TenantConfiguration } from "../../../../api/entities";

interface ExportSettingsEditorProps {
  tc: TenantConfiguration;
  onChange: (event: TenantConfiguration) => void;
}

export const ExportSettingsEditor: React.FC<ExportSettingsEditorProps> = ({
  tc,
  onChange,
}) => (
  <Grid container spacing={2} alignItems="flex-end">
    <Grid item xs={12}>
      <Typography variant={"h5"}>Export settings</Typography>
    </Grid>
    <Grid item xs={12} sm={6}>
      <ExportTypeSelector
        value={tc.export_type}
        onChange={(value) => {
          onChange({ ...tc, export_type: value });
        }}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <NumberOfRecommendationsInput
        value={tc.number_of_recommended_shoes_export}
        onChange={(value) => {
          onChange({
            ...tc,
            number_of_recommended_shoes_export: value,
          });
        }}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <StoreSettingsFilter
        value={tc.store}
        onChange={(value) => {
          onChange({ ...tc, store: value });
        }}
      />
    </Grid>
    <Grid item xs={12}>
      {tc.export_type !== "scan" && (
        <PPFilter
          data={tc.previous_purchase_reference}
          referenceSelector={tc.reference_shoe}
          onSelectorChange={(value) => {
            onChange({ ...tc, reference_shoe: value });
          }}
          onDataChange={(value) => {
            onChange({ ...tc, previous_purchase_reference: value });
          }}
        />
      )}
    </Grid>
    <Grid item xs={12}>
      <RecommendationFilterSettings
        exportType={tc.export_type}
        value={tc.recommendation_filters}
        onChange={(value) => {
          onChange({ ...tc, recommendation_filters: value });
        }}
      />
    </Grid>
  </Grid>
);
