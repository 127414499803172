import React from "react";
import { Box, Grid, TextField, Typography } from "@material-ui/core";
import { ShoeBrandFilter } from "../../../../../api/entities";

interface ShoeBrandFilterSettingsProps {
  value: ShoeBrandFilter;
  onChange: (event: ShoeBrandFilter) => void;
}

export const ShoeBrandFilterSettings: React.FC<ShoeBrandFilterSettingsProps> =
  ({ value, onChange }) => (
    <Box>
      <Typography>
        <b>Shoe brand and/or style filter.</b>
      </Typography>
      <Typography variant={"caption"}>
        Filter shoes to only recommend the provided brand and/or style.
      </Typography>
      <Grid container spacing={2} style={{ paddingTop: 0 }}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Brand"
            value={value.brand}
            onChange={(e) => {
              onChange({ ...value, brand: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            disabled={value.brand === ""}
            label="Style"
            value={value.style}
            onChange={(e) => {
              onChange({ ...value, style: e.target.value });
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
