import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { AvailableShoes } from "../../../../../api/entities";

export interface ShoeAvailabilityProps {
  value: AvailableShoes;
  onChange: (event: AvailableShoes) => void;
}

export const ShoeAvailability: React.FC<ShoeAvailabilityProps> = ({
  value,
  onChange,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let v = parseInt(event.target.value, 10);
    if (isNaN(v)) {
      v = 0;
    }
    if (
      event.target.name === "from_online" ||
      event.target.name === "from_store"
    )
      onChange({
        ...value,
        [event.target.name]: v,
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>
          <b>Shoe availability</b>
        </Typography>
        <Typography variant={"caption"}>
          Only shoes avaible from the point of sale or the online inventory will
          be recommended.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name={"from_store"}
          fullWidth
          label="Store"
          helperText="Minimum number of shoes that should be available from the point of sale."
          value={value.from_store}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name={"from_online"}
          fullWidth
          label="Online"
          helperText="Minimum number of shoes that should be available online."
          value={value.from_online}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
};
