import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "notistack";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { apiBaseUrl } from "../lib/constants";
import { TenantConfiguration } from "./entities";

export const useGetConfiguration = (id: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const key = `/v1alpha/${id}/configuration`;
  return useQuery<TenantConfiguration>(
    ["configuration", id],
    async () => {
      const token = await getAccessTokenSilently();
      const request = await fetch(`${apiBaseUrl}${key}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (request.status !== 200) {
        throw new Error(await request.text());
      }
      return await request.json();
    },
    {
      enabled: !!id,
      refetchInterval: false,
    }
  );
};

export const useUpdateConfiguration = (id: string) => {
  const { enqueueSnackbar } = useSnackbar();

  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const key = `/v1alpha/${id}/configuration`;
  return useMutation<TenantConfiguration, any, TenantConfiguration>(
    ["configuration", id],
    async (body: TenantConfiguration) => {
      const token = await getAccessTokenSilently();
      const request = await fetch(`${apiBaseUrl}${key}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (request.status !== 200) {
        throw new Error(await request.text());
      }
      return await request.json();
    },
    {
      onSuccess: (data) => {
        enqueueSnackbar("Configuration saved!", { variant: "success" });
        queryClient.setQueryData(["configuration", id], data);
      },
      onError: () => {
        enqueueSnackbar("could not save the configuration!", {
          variant: "error",
        });
      },
    }
  );
};
