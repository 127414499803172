import React, { useCallback, useMemo } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Tenant } from "../../hooks/useQuery";
import { useHistory } from "react-router-dom";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
));

export interface TenantSelectorProps {
  tenants: Tenant[];
}

export const TenantSelector: React.FC<TenantSelectorProps> = ({ tenants }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  let history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = useCallback(
    (id: string) => {
      history.push("/admin?tenant=" + id);
    },
    [history]
  );

  const items = useMemo(
    () =>
      tenants.map((t) => (
        <MenuItem dense key={t.id} onClick={() => handleItemClick(t.id)}>
          <ListItemText primary={t.name} />
        </MenuItem>
      )),
    [tenants, handleItemClick]
  );

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        disableElevation
        onClick={handleClick}
        endIcon={Boolean(anchorEl) ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
      >
        Tenant
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items}
      </StyledMenu>
    </div>
  );
};
