import React from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { FitDefinition, FitFilter } from "../../../../../api/entities";

export interface FiltFilterProps {
  value: FitFilter;
  onChange: (event: FitFilter) => void;
}

const fitOptions: { label: string; value: FitDefinition }[] = [
  { label: "Best", value: "best" },
  { label: "Good", value: "good_enough" },
];

export const FiltFilter: React.FC<FiltFilterProps> = ({ value, onChange }) => {
  const handleDefinitionChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    onChange({
      ...value,
      definition: event.target.value as FitDefinition,
    });
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let v = parseInt(event.target.value, 10);
    if (isNaN(v)) {
      v = 0;
    }
    onChange({
      ...value,
      amount: v,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Amount"
          value={value.amount}
          onChange={handleAmountChange}
        />
      </Grid>
      <Grid item xs={8}>
        <FormControl fullWidth>
          <InputLabel>Fit definition</InputLabel>
          <Select
            fullWidth
            value={value.definition}
            onChange={handleDefinitionChange}
          >
            {fitOptions.map((o) => (
              <MenuItem key={o.value} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 0 }}>
        <Typography variant={"caption"}>
          Minimum amount of recommended shoes that have the desired fit.
        </Typography>
      </Grid>
    </Grid>
  );
};
