import { useAuth0 } from "@auth0/auth0-react";
import { AppBar, Button, Toolbar, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { TenantSelector } from "./TenantSelector";

import tenants from "../../tenant-config.json";

import MailIcon from "@material-ui/icons/Mail";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginRight: theme.spacing(1),
    },
    title: {
      marginRight: theme.spacing(2),
    },
    name: {
      marginRight: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
    },
    push: {
      flexGrow: 1,
    },
  })
);

export const TopBar = () => {
  const classes = useStyles();
  const { user, isAuthenticated, logout } = useAuth0();
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <MailIcon className={classes.icon} />
          <Typography variant="h6" className={classes.title}>
            Volumental Promo
          </Typography>
          {isAuthenticated && <TenantSelector tenants={tenants} />}
          <div className={classes.push}></div>
          {isAuthenticated ? (
            <>
              <Typography className={classes.name}>{user?.name}</Typography>
              <Button
                color="inherit"
                onClick={() =>
                  logout({
                    returnTo: window.location.origin + "/signed-out",
                  })
                }
              >
                Logout
              </Button>
            </>
          ) : null}
        </Toolbar>
      </AppBar>
    </div>
  );
};
